import {
    GET_PARTNERS_API_REQUEST,
    GET_PARTNERS_API_SUCCESS,
    GET_PARTNERS_API_ERROR,
} from '../../../constants/portfolio/getPartnersActionNames';

import { getPartnersApi } from '../../../apis/portfolio';

function getPartnersRequest() {
    return {
        type: GET_PARTNERS_API_REQUEST,
        payload: true,
    };
}

function getPartnersSuccess(data) {
    return {
        type: GET_PARTNERS_API_SUCCESS,
        payload: data,
    };
}

function getPartnersError(err) {
    return {
        type: GET_PARTNERS_API_ERROR,
        payload: err,
    };
}

export function getPartnersAction(params) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(getPartnersRequest());
        return getPartnersApi.getPartnersApi(state, params)
            .then(data => {
                dispatch(getPartnersSuccess(data?.payload));
                return data;
            })
            .catch(error => {
                dispatch(getPartnersError(error));
                throw error;
            });
    };
}
export default {
    getPartnersAction,
};
