import { parseBool } from './boolManipulation';

export const nFormatter = (num, digits) => {
  const sign = Math.sign(num);
  const numAbs = Math.abs(num);
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (numAbs >= si[i].value) {
      break;
    }
  }
  const reverseNum = sign >= 0 ? Math.abs(numAbs) : -Math.abs(numAbs);
  const result =
    (reverseNum / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
  return result;
};

export const formatDataTableValue = ({
  inputValue,
  isNumeric = false,
  isCurrency = false,
  isPercent = false,
  isLocalize = false,
  isAbbrev = false,
  isBoolean = false,
  units,
  showOriginalUnits = false,
  showDataUnits = false,
  decimal = 2,
  abbrevDigits = 2,
  showZeroIfNotValidNumber = false,
  showEmptyIfNotValidNumber = false,
  emptyValue = '',
  currencySymbol = '$',
}) => {
  let dataValue = inputValue;
  dataValue = isBoolean ? parseBool(dataValue) : dataValue;
  let originalValue = dataValue;
  if ((!dataValue && dataValue !== 0) || Number.isNaN(parseFloat(dataValue))) {
    if (showZeroIfNotValidNumber) {
      dataValue = 0;
    } else if (showEmptyIfNotValidNumber) {
      return { dataValue: emptyValue, originalValue: emptyValue };
    } else {
      return { dataValue, originalValue };
    }
  }
  if (!Number.isNaN(parseFloat(dataValue)) && parseFloat(dataValue) === 0) {
    dataValue = isCurrency ? `${currencySymbol}${dataValue}` : dataValue;
    dataValue = isPercent ? `${dataValue}%` : dataValue;
    dataValue = units && showDataUnits ? `${dataValue} ${units}` : dataValue;
    originalValue = isCurrency ? `${currencySymbol}${originalValue}` : originalValue;
    originalValue = isPercent ? `${originalValue}%` : originalValue;
    originalValue =
      units && showOriginalUnits ? `${originalValue} ${units}` : originalValue;
    return { dataValue, originalValue };
  }
  if (isNumeric && (!inputValue || Number.isNaN(parseFloat(inputValue)))) {
    return { dataValue: 'N/A', originalValue };
  }

  dataValue = isNumeric ? parseFloat(inputValue) : inputValue;
  /* let originalValue = isLocalize
    ? dataValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : dataValue; */
  originalValue = isCurrency
    ? dataValue.toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      })
    : dataValue?.toLocaleString() || dataValue;
  originalValue = isCurrency ? `${currencySymbol}${originalValue}` : originalValue;
  originalValue = isPercent ? `${originalValue}%` : originalValue;
  originalValue =
    units && showOriginalUnits ? `${originalValue} ${units}` : originalValue;

  dataValue =
    isLocalize && !isAbbrev
      ? dataValue.toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal,
        })
      : dataValue;
  dataValue = isAbbrev ? nFormatter(dataValue, abbrevDigits) : dataValue;
  dataValue = isCurrency ? `${currencySymbol}${dataValue}` : dataValue;
  dataValue = isPercent ? `${dataValue}%` : dataValue;
  dataValue = units && showDataUnits ? `${dataValue} ${units}` : dataValue;

  return { dataValue, originalValue };
};

export const formatTextToCurrency = (text, isCurrency = false) => {
  if (isCurrency) {
    const noCost = '0.00 ';
    let number = Number(text.replace(/[^0-9.-]+/g, ''));
    number = [0, ''].includes(number) ? noCost : number;
    let result = text.replace(number, number.toLocaleString());
    if (number === noCost && result === text) {
      result = text.replace(' ', number);
    }
    return result;
  }
  return text;
};
